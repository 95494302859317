import { FC } from 'react';

export const WarmWaterIcon: FC = () => (
	<svg
		width="28"
		height="29"
		viewBox="0 0 28 29"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby="warm-water-icon"
	>
		<title id="warm-water-icon" lang="ja">
			温水アイコン {/* Warm water icon */}
		</title>
		<g clipPath="url(#clip0_2975_3208)">
			<path
				d="M11.1753 0.739501C9.3561 0.78504 0.285536 10.8023 0.0755779 18.0947C-0.141552 25.6454 6.40495 28.6927 10.7632 28.7389C14.8046 28.7813 23.0268 26.7901 21.8342 17.4703C20.8972 10.1448 13.1216 0.691467 11.1753 0.739501Z"
				fill="#6675F6"
			/>
			<path
				d="M12.7005 28.6091C11.9696 28.7077 11.2862 28.7451 10.6929 28.7389C6.33464 28.6934 -0.211865 25.646 0.00526545 18.0953C0.153279 12.9482 4.71562 6.44369 8.02604 3.04639C5.12706 6.71068 1.17503 13.9164 1.66993 19.1814C2.3298 26.2112 8.37488 28.6759 12.7005 28.6091Z"
				fill="#5840FF"
			/>
			<path
				d="M24.4993 14.5745V4.28778C24.5025 4.24598 24.5051 4.20356 24.5051 4.16114C24.5051 3.02579 23.246 2.10504 21.6935 2.10504C20.141 2.10504 18.8819 3.02579 18.8819 4.16114C18.8819 4.18547 18.8832 4.2098 18.8845 4.23413V14.5003C16.721 15.479 15.2246 17.5863 15.2246 20.0273C15.2246 23.4028 18.0845 26.1388 21.6127 26.1388C25.1409 26.1388 28.0007 23.4028 28.0007 20.0273C28.0007 17.6462 26.5767 15.5826 24.4993 14.5745Z"
				fill="#F0FFFF"
			/>
			<path
				d="M22.6183 15.8795V4.8305C22.6183 4.37013 22.2277 3.99646 21.7465 3.99646C21.2653 3.99646 20.8748 4.37013 20.8748 4.8305V15.8421C18.7928 16.1927 17.2109 17.9313 17.2109 20.0236C17.2109 22.3685 19.1977 24.2692 21.6487 24.2692C24.0998 24.2692 26.0865 22.3685 26.0865 20.0236C26.0865 17.9974 24.6031 16.3031 22.6183 15.8795Z"
				fill="#F050F8"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2975_3208">
				<rect
					width="28"
					height="28"
					fill="white"
					transform="translate(0 0.739502)"
				/>
			</clipPath>
		</defs>
	</svg>
);
