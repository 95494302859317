import { IconEV } from '@krakentech/icons';
import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { CrownCircle } from '@/components/svgs/CrownCircle';
import { EarthIcon } from '@/components/svgs/EarthIcon';
import { HouseIcon } from '@/components/svgs/HouseIcon';
import { ShieldCheck } from '@/components/svgs/ShieldCheck';
import { SunIcon } from '@/components/svgs/SunIcon';
import { TrophyGold } from '@/components/svgs/TrophyGold';
import { WarmWaterIcon } from '@/components/svgs/WarmWaterIcon';
import { YenNoFees } from '@/components/svgs/YenNoFees';
import { ZeroWithEmphasis } from '@/components/svgs/ZeroWithEmphasis';
import { ContractCapacityPattern } from '@/services/typed-graphql-sdk';
import { ColorHex } from '@/utils/constants/colors';

export type ProductCode =
	| 'Green'
	| 'Standard'
	| 'EV'
	| 'Solar'
	| 'Minatomirai'
	| 'AllElectric';

const BulletPoint: FC<{
	content: ReactNode;
	icon: ReactNode;
	iconLabel: string;
}> = ({ content, icon, iconLabel }) => (
	<div className="flex items-center">
		<div className="mr-4">
			<span role="img" aria-label={iconLabel}>
				{icon}
			</span>
		</div>
		<span className="text-sm font-bold sm:text-base">{content}</span>
	</div>
);

const DetailElementContent: Record<string, ReactNode> = {
	NoFees: (
		<span>
			<Trans
				i18nKey="obj:product.details.no-fees"
				components={{
					spanVoltage: <span className="text-voltage" />,
				}}
			/>
		</span>
	),
	RenewableEnergy: (
		<span>
			<Trans
				i18nKey="obj:product.details.100-percent-renewable-energy"
				components={{
					spanVoltage: <span className="text-voltage" />,
				}}
			/>
		</span>
	),
	/**
	 * @important
	 * This USP must be accompanied by legal disclaimer copy.
	 * i18n key: obj:product.details.ofgem-retailers-explainer
	 */
	NumberOneInUK: (
		<span>
			<Trans
				i18nKey="obj:product.details.number-1-in-uk"
				components={{
					spanVoltage: <span className="text-voltage" />,
				}}
			/>
		</span>
	),
	AwardWinning: (
		<span>
			<Trans
				i18nKey="obj:product.details.award-winning-customer-service"
				components={{
					spanVoltage: <span className="text-voltage" />,
				}}
			/>
		</span>
	),
	NoCarbonEmissions: (
		<span>
			<Trans
				i18nKey="obj:product.no-carbon-emissions"
				components={{
					spanVoltage: <span className="text-voltage" />,
				}}
			/>
		</span>
	),
};

export const DetailElementByProductCode: Record<ProductCode, FC> = {
	Solar: () => {
		const { t } = useTranslation();
		return (
			<>
				{[
					{
						icon: (
							<div className="ml-1">
								<SunIcon width={20} />
							</div>
						),
						iconLabel: 'Sun Symbol',
						content: t('obj:product.details.save-money-at-night'),
					},
					{
						icon: <YenNoFees width={28} />,
						iconLabel: '円',
						content: DetailElementContent.NoFees,
					},
					{
						icon: <ShieldCheck width={28} />,
						iconLabel: 'シールド',
						content: DetailElementContent.RenewableEnergy,
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
			</>
		);
	},
	EV: () => {
		const { t } = useTranslation();
		return (
			<>
				{[
					{
						icon: (
							<div className="ml-1">
								<IconEV color={ColorHex.akoya} />
							</div>
						),
						iconLabel: 'Electric Vehicle',
						content: t('obj:product.details.great-deal-at-ev-time'),
					},
					{
						icon: <YenNoFees width={28} />,
						iconLabel: '円',
						content: DetailElementContent.NoFees,
					},
					{
						icon: <ShieldCheck width={28} />,
						iconLabel: 'シールド',
						content: DetailElementContent.RenewableEnergy,
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
			</>
		);
	},
	Green: () => {
		const { t } = useTranslation();
		const data = [
			{
				icon: <YenNoFees width={28} />,
				iconLabel: '円',
				content: DetailElementContent.NoFees,
			},
			{
				icon: <ShieldCheck width={28} />,
				iconLabel: 'シールド',
				content: DetailElementContent.RenewableEnergy,
			},
			{
				icon: <CrownCircle width={28} />,
				iconLabel: '王冠',
				content: DetailElementContent.NumberOneInUK,
			},
			{
				icon: <TrophyGold width={28} />,
				iconLabel: 'トロフィー',
				content: DetailElementContent.AwardWinning,
			},
		];
		return (
			<>
				{data.map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
				<p className="text-xs text-dustysky">
					{t('obj:product.details.ofgem-retailers-explainer')}
				</p>
			</>
		);
	},
	Minatomirai: () => {
		const { t } = useTranslation();
		return (
			<>
				{[
					{
						icon: <ShieldCheck width={28} />,
						iconLabel: 'シールド',
						content: DetailElementContent.RenewableEnergy,
					},
					{
						icon: <EarthIcon />,
						iconLabel: '地球',
						content: t('obj:product.details.minatomirai-donate'),
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
			</>
		);
	},
	Standard: () => {
		const { t } = useTranslation();
		return (
			<>
				{[
					{
						icon: <YenNoFees width={28} />,
						iconLabel: '円',
						content: DetailElementContent.NoFees,
					},
					{
						icon: <CrownCircle width={28} />,
						iconLabel: '王冠',
						content: DetailElementContent.NumberOneInUK,
					},
					{
						icon: <TrophyGold width={28} />,
						iconLabel: 'トロフィー',
						content: DetailElementContent.AwardWinning,
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
				<p className="text-xs text-dustysky">
					{t('obj:product.details.ofgem-retailers-explainer')}
				</p>
			</>
		);
	},
	AllElectric: () => {
		const { t } = useTranslation();
		return (
			<div className="flex flex-col space-y-5 pt-2">
				{[
					{
						icon: <YenNoFees width={28} />,
						iconLabel: '円',
						content: DetailElementContent.NoFees,
					},
					{
						icon: <ZeroWithEmphasis />,
						iconLabel: 'ゼロ',
						content: DetailElementContent.NoCarbonEmissions,
					},
					{
						icon: <HouseIcon />,
						iconLabel: '家',
						content: t(
							'obj:product.details.all-electric-recommended-for-homes'
						),
					},
					{
						icon: <WarmWaterIcon />,
						iconLabel: 'トロフィー',
						content: t(
							'obj:product.details.all-electric-recommended-for-night-water-heating'
						),
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
			</div>
		);
	},
};

/**
 * For the slide-out drawer AB test variant (code: AB_TEST_HOMEPAGE_ESTIMATE)
 */
export const DetailElementGreenProductVariant: FC = () => {
	const data = [
		{
			icon: <YenNoFees width={28} />,
			iconLabel: '円',
			content: (
				<>
					解約金・解約手数料も<span className="text-voltage">無料！</span>
				</>
			),
		},
		{
			icon: <ShieldCheck width={28} />,
			iconLabel: 'シールド',
			content: (
				<>
					実質再生可能エネルギー<span className="text-voltage">100%</span>
				</>
			),
		},
		{
			icon: <CrownCircle width={28} />,
			iconLabel: '王冠',
			content: (
				<>
					英国シェア
					<span className="text-voltage">No.1*</span>
					の電力会社
				</>
			),
		},
		{
			icon: <TrophyGold width={28} />,
			iconLabel: 'トロフィー',
			content: (
				<>
					英国で<span className="text-voltage">100以上のアワード</span>
					獲得
				</>
			),
		},
	];
	return (
		<>
			{data.map(({ icon, iconLabel, content }) => (
				<BulletPoint
					icon={icon}
					iconLabel={iconLabel}
					key={iconLabel}
					content={content}
				/>
			))}
		</>
	);
};

const GreenProductDetails: FC = () => {
	const { t } = useTranslation();
	const data = [
		{
			icon: <YenNoFees width={28} />,
			iconLabel: '円',
			content: DetailElementContent.NoFees,
		},
		{
			icon: <ZeroWithEmphasis />,
			iconLabel: 'ゼロ',
			content: DetailElementContent.NoCarbonEmissions,
		},
		{
			icon: <CrownCircle width={28} />,
			iconLabel: '王冠',
			content: DetailElementContent.NumberOneInUK,
		},
		{
			icon: <TrophyGold width={28} />,
			iconLabel: 'トロフィー',
			content: DetailElementContent.AwardWinning,
		},
	];
	return (
		<>
			{data.map(({ icon, iconLabel, content }) => (
				<BulletPoint
					icon={icon}
					iconLabel={iconLabel}
					key={iconLabel}
					content={content}
				/>
			))}
			<p className="text-xs text-dustysky">
				{t('obj:product.details.ofgem-retailers-explainer')}
			</p>
		</>
	);
};

export type DetailElementProductKey =
	keyof typeof DetailElementByProductCodeMay2024;

export const DetailElementByProductCodeMay2024: Record<
	'AllElectric' | 'Green' | 'Simple' | 'Standard',
	FC
> = {
	AllElectric: GreenProductDetails,
	Green: GreenProductDetails,
	Simple: GreenProductDetails,
	Standard: () => {
		const { t } = useTranslation();
		return (
			<>
				{[
					{
						icon: <YenNoFees width={28} />,
						iconLabel: '円',
						content: DetailElementContent.NoFees,
					},
					{
						icon: <CrownCircle width={28} />,
						iconLabel: '王冠',
						content: DetailElementContent.NumberOneInUK,
					},
					{
						icon: <TrophyGold width={28} />,
						iconLabel: 'トロフィー',
						content: DetailElementContent.AwardWinning,
					},
				].map(({ icon, iconLabel, content }) => (
					<BulletPoint
						icon={icon}
						iconLabel={iconLabel}
						key={iconLabel}
						content={content}
					/>
				))}
				<p className="text-xs text-dustysky">
					{t('obj:product.details.ofgem-retailers-explainer')}
				</p>
			</>
		);
	},
};

/**
 * Monthly usage estimates given by Jumpei from internal surveys.
 * Months that account for between 5-7% of annual consumption are classed as "regular"
 * and months that account for over 7% of annual usage will be classed as "high".
 */
export const MONTH_USAGE_SURVEY_AVERAGES = [
	{
		display: '1月',
		displayEnglish: 'J',
		consumption: 'high',
		month: '1',
	},
	{
		display: '2月',
		displayEnglish: 'F',
		consumption: 'high',
		month: '2',
	},
	{ display: '3月', displayEnglish: 'M', consumption: 'high', month: '3' },
	{ display: '4月', displayEnglish: 'A', consumption: 'high', month: '4' },
	{ display: '5月', displayEnglish: 'M', consumption: 'high', month: '5' },
	{
		display: '6月',
		displayEnglish: 'J',
		consumption: 'regular',
		month: '6',
	},
	{
		display: '7月',
		displayEnglish: 'J',
		consumption: 'regular',
		month: '7',
	},
	{
		display: '8月',
		displayEnglish: 'A',
		consumption: 'regular',
		month: '8',
	},
	{
		display: '9月',
		displayEnglish: 'S',
		consumption: 'regular',
		month: '9',
	},
	{
		display: '10月',
		displayEnglish: 'O',
		consumption: 'regular',
		month: '10',
	},
	{
		display: '11月',
		displayEnglish: 'N',
		consumption: 'high',
		month: '11',
	},
	{
		display: '12月',
		displayEnglish: 'D',
		consumption: 'high',
		month: '12',
	},
];

export const KVA_TABLE_NAMES_BY_CONTRACT_CAPACITY_PATTERN: Record<
	ContractCapacityPattern,
	string
> = {
	TIERED_LOW: '6kVA未満の基本料金',
	TIERED_HIGH: '6kVA以上の基本料金',
	NON_TIERED: 'kVA契約の方',
	OEPC: '',
};

// Constants from Kraken Core:
// https://github.com/octoenergy/kraken-core/blob/master/src/octoenergy/plugins/territories/jpn/markets/electricity/quoting/domain/constants.py
export const SELF_CONSUMPTION_RATE_WITH_ACCUMULATOR = 0.7;
export const SELF_CONSUMPTION_RATE_WITHOUT_ACCUMULATOR = 0.3;
