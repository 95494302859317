/**
 * Pads a number to two decimal places.
 * @param num
 * @returns string
 *
 * Note that `Intl.NumberFormat` will round numbers when it's more than the maximum fraction digits.
 */

export const padNumberWithTrailingZeroesToTwoDecimals = (
	num: number
): string => {
	return new Intl.NumberFormat('ja-JP', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(num);
};
